import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";

function Appointment() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Appointment">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Appointment"
          Subheader="Appointment"
          bgimg={`${rpdata?.stock?.[2]}`}
        />
        <div className="py-[100px] bg-[#f9e4da]">
          <div className="elfsight-app-99df60a5-102e-4ebf-9490-41fc30beb1d0"></div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Appointment;
